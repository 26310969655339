@tailwind base;
@tailwind components;
@tailwind utilities;

@import url('https://fonts.googleapis.com/css2?family=Raleway:wght@300;400;500;600;700;800;900&display=swap');

:root {
  --color-primary: #0a192f;
  --color-secondary: #3fc1c9;
  --color-teritiary: #ccd6f6;
}

@layer base {
  body {
    @apply font-[Raleway];
    @apply text-gray-300;
  }

  li {
    @apply px-4;
    @apply cursor-pointer;
  }
}

@layer components {
  .container-mobile {
    @apply px-10 py-20;
  }

  .side-bar {
    @apply w-[160px] h-[60px] flex justify-between items-center ml-[-100px] hover:ml-[-10px] duration-300 rounded-r;
  }

  .box-shadow {
    @apply shadow-md shadow-[#040c16] hover:scale-110 duration-500;
  }

  .input-field {
    @apply p-2 bg-color-teritiary rounded text-black;
  }
}

@layer utilities {
  .center {
    @apply flex justify-center items-center;
  }

  .active {
    @apply border-b-[3px] border-[#3FC1C9] inline pb-0.5;
  }

  .nav-link-hover {
    @apply hover:border-b-[3px] border-[#3FC1C9] inline pb-0.5;
  }
}

.content-div {
  background-repeat: no-repeat;
  background-size: 100% 100%;
  background-position: center;
  height: 250px;
}

.content-div:hover {
  background-image: linear-gradient(
    45deg,
    hsl(0deg 0% 96%) 0%,
    hsl(183deg 28% 92%) 11%,
    hsl(183deg 37% 88%) 22%,
    hsl(184deg 42% 83%) 33%,
    hsl(184deg 45% 79%) 44%,
    hsl(184deg 48% 74%) 56%,
    hsl(184deg 49% 69%) 67%,
    hsl(184deg 51% 64%) 78%,
    hsl(184deg 53% 59%) 89%,
    hsl(183deg 56% 52%) 100%
  ) !important;
}
